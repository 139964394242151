import { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

import About from "./pages/About";
import Akar from "./pages/Akar";
import AkarProductDetail from "./pages/AkarProductDetail";
import ContactForm from "./pages/Contact";
import Error404Page from "./pages/Error404Page";
import FrontPage from "./pages/FrontPage";
import Products from "./pages/Products";
import { closePage } from "./utils/NavigationUtils";

import EventEmitter from "events";

let src = new EventEmitter();

export default function App() {
    return <Fragment>
        <div id="wrapper" onClick={(e) => src.emit('bg_click', e)}>
        <Router>
            <div id="main">
                <AppPageSwitcher events={src} />
                <Footer />
            </div>
        </Router>
        </div>
        <div id="bg" />
    </Fragment>
}

function AppPageSwitcher(props: {
    events?: EventEmitter
}) {

    const navigate = useNavigate();

    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.code === "Escape" && location.pathname !== "/") {
            closePage(navigate);
        }
        event.stopImmediatePropagation();
    }

    useEffect(() => {
        window.addEventListener("keyup", handleKeyUp);
        props.events?.on("bg_click", (e: MouseEvent)=> {
            if (e.target instanceof HTMLElement) {
                if (e.target == document.querySelector("div#wrapper")) {
                    closePage(navigate);
                }
            }
        });
    }, []);

    return <Routes>

        {/* <!-- Home Page --> */}
        <Route path="/" element={<FrontPage/>} />

        {/* <!-- About --> */}
        <Route path="/about" element={<About/>} />

        {/* <!-- Products --> */}
        <Route path="/products"                   element={<Products/>}/>
        <Route path="/products/akar"              element={<Akar/>} />
        <Route path="/products/akar/:productName" element={<AkarProductDetail/>} />

        {/* <!-- Contact --> */}
        <Route path="/contact"         element={<ContactForm/>} />

        {/* <!-- 404 --> */}
        <Route path="*" element={<Error404Page/>} />

    </Routes>
}

function Footer() {
    return <footer id="footer">
        <p className="copyright">
            <span style={{"margin": 12}} >
                <strong>&copy; Composite Nirman Materials Pvt Ltd.</strong>
            </span>
            <span style={{"margin": 8}} >
                Design: <a href="https://in.linkedin.com/in/swanav" target="_blank" rel="noopener">Swanav</a>.
            </span>
            <span style={{"margin": 8}} >
                Template: <a href="https://html5up.net" target="_blank" rel="noopener">HTML5 UP</a>.
            </span>
        </p>
    </footer>
}
