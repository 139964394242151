import { useState } from "react";
import { Link } from "react-router-dom";

import CNMLogo from "../images/brand/CNM.webp";
import { navPath } from "../utils/NavigationUtils";

export default function FrontPage() {

    const [active, setActive] = useState(false);

    setTimeout(() => setActive(true), 0)

    return <header id="header" style={{opacity: active ? 1 : 0}} >
        <div className="logo">
            <img style={{ width: "100%", height: "100%" }} alt="CNM Logo" src={CNMLogo} />
        </div>
        <div className="content">
            <div className="inner">
                <h1>Composite Nirman Materials Pvt Ltd</h1>
                <p>Shaping your Dreams</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to={navPath("/about", location.pathname)}>About</Link></li>
                <li><Link to={navPath("/products", location.pathname)}>Products</Link></li>
                <li><Link to={navPath("/contact", location.pathname)}>Contact</Link></li>
            </ul>
        </nav>
    </header>;
}
