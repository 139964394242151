import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { closePage } from '../utils/NavigationUtils';

import { products } from "./AkarCatalog";


export default function AkarProductDetail() {


    const navigate = useNavigate();

    const closeHandler = () => {
        closePage(navigate, "/products/akar");
    }

    const [active, setActive] = useState(false);
    setTimeout(() => setActive(true), 0)

    const { productName } = useParams();
    const product = products.find((prod) => prod.name.toLowerCase().split(" ").join("-").replace("/", "-") === productName)

    if ( product === undefined ) {
        return <Navigate to="/404"></Navigate>
    }

    return (
        <article id={`akar-${product.name}`} className={active ? "active" : ""} >
            <div className="close" onClick={closeHandler}>Close</div>
            <div className="product">
                <h2 className="minor">{product.name}</h2>
                <div className="product-detail">
                    <div className="product-header">
                        <span className="image main product-image" style={{ margin: "0px 8px 8px 0px" }}>
                            <img src={product.image} alt="" />
                        </span>
                        <div className="product-description">{product.description}</div>
                    </div>
                    <div>
                        <h4>Sizes</h4>
                        <div className="table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Size</th>
                                        <th>Suitable For</th>
                                        <th>&#x25A0;</th>
                                        <th className="half-line">&#x25A0;&#x25A0;<br /> &#x25A0;&#x25A0;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        product.sizes.map((size) => {
                                            return <tr key={size.size + size.suitableFor}>
                                                <td>{size.size}</td>
                                                <td>{size.suitableFor}</td>
                                                <td>{size.boxQty}</td>
                                                <td>{size.cartonQty}</td>
                                            </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                    product.operatingConditions === undefined ? '' :
                        <div>
                            <h4>Operating Conditions</h4>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        {
                                        product.operatingConditions.map((item) => {
                                            return <tr key={item.parameter}>
                                                <td>{item.parameter}</td>
                                                <td>{item.value}</td>
                                            </tr>;
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        {
        product.specifications === undefined ? '' :
            <div>
                <h2 className="minor">Product Specifications</h2>
                <span className="image main product-image"><img src={product.specifications.image} alt="" /></span>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Part</th>
                            <th>Material</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        product.specifications.items.map((item, index) => {
                            return <tr key={index + item.name}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.material}</td>
                            </tr>;
                        })
                        }
                    </tbody>
                </table>
            </div>
        }
        </article>
    )
}
