import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { closePage, navPath } from "../utils/NavigationUtils";
import { ProductItem, products } from "./AkarCatalog";


export default function Akar() {

    const navigate = useNavigate();

    const closeHandler = () => {
        closePage(navigate, "/products");
    }

    const [active, setActive] = useState(false);

    setTimeout(() => setActive(true), 0)

    return (
        <article id="akar" className={active ? "active" : ""} >
            <div className="close" onClick={closeHandler}>Close</div>
            <a href="assets/docs/AKAR_Catalogue_v3.pdf" download target="_blank" className="button icon solid fa-download" style={{ float: "right" }}>Product Catalog</a>
            <h2 className="major">AKAR</h2>
            <ul className="alt products">
                {
                    products.map((product, index) => {
                        return <li key={product.name}><ProductSection index={index} product={product} /></li>
                    })
                }
            </ul>
        </article>
    )

}

function ProductSection(props: { index: number, product: ProductItem }) {

    const { index, product } = props;

    const productDetailPageUrl = `/products/akar/` + product.name.toLowerCase().split(" ").join("-").replace("/", "-")

    return (
        <div className="product">
            <h2 className="minor">{product.name}</h2>
            <div className="product-detail">
                <div className="product-header">
                    {
                        index % 2 == 0 ?
                            <Fragment>
                                <span className="image main product-image" style={{ margin: "0px 8px 8px 0px" }}><img src={product.image} alt="" /></span>
                                <div className="product-description">{product.description}</div>
                            </Fragment>
                            :
                            <Fragment>
                                <div className="product-description">{product.description}</div>
                                <span className="image main product-image" style={{ margin: "0px 0px 8px 8px" }}><img src={product.image} alt="" /></span>
                            </Fragment>
                    }
                </div>
            </div>
            <Link to={navPath(productDetailPageUrl, location.pathname)} className="button icon solid fa-download">Read More...</Link>
        </div>
    )
}
