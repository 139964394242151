
import PAPQTConcealValveImage from "../images/products/01.webp";
import PAPBallValveImage from "../images/products/02.webp";
import PAPOHTNippleImage from "../images/products/03.webp";
import PAPNonReturnValveImage from "../images/products/04.webp";
import PAPFerruleValveImage from "../images/products/05.webp";
import GasBallMaleValveImage from "../images/products/06.webp";
// import GasBallFemaleValveImage from "../images/products/07.webp";
// import GasBallMaleFemaleValveImage from "../images/products/08.webp";
// import GasBallDSValveImage from "../images/products/09.webp";
import PAPBallValveCrossImage from "../images/products/10.webp";
import PAPNonReturnValveCrossImage from "../images/products/11.webp";
import PAPOHTNippleCrossImage from "../images/products/12.webp";
import GasBallValveCrossImage from "../images/products/13.webp";

export interface ProductItem {
    name: string;
    image: string;
    description: string;
    sizes: {
        size: string;
        suitableFor: string;
        boxQty: number;
        cartonQty: number;
    }[];
    operatingConditions?: {
        parameter: string;
        value: string;
    }[];
    specifications?: {
        image: string;
        items: {
            name: string;
            material: string;
        }[];
    };
}


export const products: ProductItem[] = [
    {
        name: "PAP Ball Valve",
        image: PAPBallValveImage,
        description: 'Compression Type End Connection Quarter Turn Isolating Ball Valve for (PE-Al-PE) Composite Pressure Pipes.',
        sizes: [
            { size: '14\u00D714', suitableFor: '1014 Composite Pipe', boxQty: 10, cartonQty: 100 },
            { size: '16\u00D716', suitableFor: '1216 Composite Pipe', boxQty: 10, cartonQty: 100 },
            { size: '20\u00D720', suitableFor: '1620 Composite Pipe', boxQty:  8, cartonQty:  64 },
            { size: '25\u00D725', suitableFor: '2025 Composite Pipe', boxQty:  6, cartonQty:  36 },
            { size: '32\u00D732', suitableFor: '2532 Composite Pipe', boxQty:  6, cartonQty:  24 },
            { size: '40\u00D740', suitableFor: '3240 Composite Pipe', boxQty:  4, cartonQty:  16 },
            { size: '50\u00D750', suitableFor: '4050 Composite Pipe', boxQty:  2, cartonQty:   8 },
        ],
        operatingConditions: [
            { parameter: 'Pressure', value: '1.6 MPa' },
            { parameter: 'Temperature', value: '-20\u00B0C to 110\u00B0C' },
            { parameter: 'Medium', value: 'Water, Non Corrosive Liquid, Saturated Vapour' },
        ],
        specifications: {
            image: PAPBallValveCrossImage,
            items: [
                { name: 'Body', material: 'Brass' },
                { name: 'Body', material: 'Brass' },
                { name: 'Valve Ball', material: 'Brass Hard Chrome' },
                { name: 'Valve Stem', material: 'Brass' },
                { name: 'Ball Seal', material: 'PTFE' },
                { name: 'Gland Nut', material: 'Brass' },
                { name: 'Stem Seal', material: 'PTFR' },
                { name: 'Lock Nut', material: 'Stainless Steel' },
                { name: 'Handle', material: 'Stainless Steel' },
                { name: 'Compression Nut', material: 'Brass' },
                { name: 'O-Ring', material: 'NBR' },
                { name: 'Split Ring', material: 'Brass' },
            ],
        },
    },
    {
        name: 'PAP Non Return Valve',
        description: 'Compression Type End Connection Non Return Valve for (PE-Al-PE) Composite Pressure Pipes.',
        image: PAPNonReturnValveImage,
        sizes: [
            { size: '20\u00D720', suitableFor: '1620 Composite Pipe', boxQty: 15, cartonQty: 90 },
            { size: '25\u00D725', suitableFor: '2025 Composite Pipe', boxQty: 15, cartonQty: 90 },
            { size: '32\u00D732', suitableFor: '2532 Composite Pipe', boxQty:  6, cartonQty: 36 },
        ],
        operatingConditions: [
            { parameter: 'Pressure', value: '1.6 MPa' },
            { parameter: 'Temperature', value: '-20\u00B0C to 110\u00B0C' },
            { parameter: 'Medium', value: 'Water, Non Corrosive Liquid' },
        ],
        specifications: {
            image: PAPNonReturnValveCrossImage,
            items: [
                { name: 'O-Ring', material: 'NBR' },
                { name: 'Split Ring', material: 'Brass' },
                { name: 'Compression Nut', material: 'Brass' },
                { name: 'Insert', material: 'Brass' },
                { name: 'Rubber Seal', material: 'NBR' },
                { name: 'Body', material: 'Brass' },
                { name: 'Disc', material: 'Brass' },
                { name: 'Spring', material: 'Stainless Steel' },
                { name: 'Disc Axis', material: 'Brass' },
                { name: 'Body', material: 'Brass' },
            ],
        }
    },
    {
        name: 'PAP OverHead Tank Nipple',
        image: PAPOHTNippleImage,
        description: 'Compression Type End Connection Overhead Tank Nipple for (PE-Al-PE) Composite Pressure Pipes.',
        sizes: [
            { size: '20\u00D70.75"', suitableFor: '1620 Composite Pipe', boxQty: 20, cartonQty: 120 },
            { size: '25\u00D71.00"', suitableFor: '2025 Composite Pipe', boxQty:  8, cartonQty:  80 },
            { size: '32\u00D71.25"', suitableFor: '2532 Composite Pipe', boxQty:  6, cartonQty:  48 },
            { size: '40\u00D71.50"', suitableFor: '3240 Composite Pipe', boxQty:  6, cartonQty:  36 },
        ],
        operatingConditions: [
            { parameter: 'Pressure', value: '1.6 MPa' },
            { parameter: 'Temperature', value: '-20\u00B0C to 110\u00B0C' },
            { parameter: 'Medium', value: 'Water, Non Corrosive Liquid' },
        ],
        specifications: {
            image: PAPOHTNippleCrossImage,
            items: [
                { name: 'O-Ring', material: 'NBR' },
                { name: 'Split Ring', material: 'Brass' },
                { name: 'Compression Nut', material: 'Brass' },
                { name: 'Body', material: 'Brass' },
                { name: 'Rubber Gasket', material: 'NBR' },
                { name: 'Nut', material: 'Brass' },
            ],
        },
    },
    {
        name: 'Gas/Air Ball Valve',
        image: GasBallMaleValveImage,
        description: 'Manually operated gas ball valves for gas piping installations.',
        sizes: [
            { size: '1/2"x8.0mm', suitableFor: 'Male Thread',              boxQty: 10, cartonQty: 120 },
            { size: '1/2"x8.0mm', suitableFor: 'Female Thread',            boxQty: 10, cartonQty: 120 },
            { size: '1/2"x1/2"',  suitableFor: 'Male/Female Thread',       boxQty: 12, cartonQty: 144 },
            { size: '1/2"x8.0mm', suitableFor: 'Male Thread Double Swing', boxQty: 10, cartonQty: 100 }
        ],
        specifications: {
            image: GasBallValveCrossImage,
            items: [
                { name: 'Body', material: 'Brass' },
                { name: 'Body', material: 'Brass' },
                { name: 'Valve Ball', material: 'Brass Hard Chrome' },
                { name: 'Ball Seal', material: 'PTFE' },
                { name: 'Stem Seal', material: 'PTFR' },
                { name: 'Valve Stem', material: 'Brass' },
                { name: 'Lock Nut', material: 'Stainless Steel' },
                { name: 'Handle', material: 'Stainless Steel' },

            ]
        },
    },
    {
        name: 'PAP Quarter Turn Conceal Valve',
        image: PAPQTConcealValveImage,
        description: 'Compression Type End Connection Non Return Valve for (PE-Al-PE) Composite Pressure Pipes.',
        sizes: [
            { size: '20\u00D720', suitableFor: '1620 Composite Pipe', boxQty: 1, cartonQty: 42 },
        ],
        operatingConditions: [
            { parameter: 'Pressure', value: '1.6 MPa' },
            { parameter: 'Temperature', value: '-20\u00B0C to 110\u00B0C' },
            { parameter: 'Medium', value: 'Water, Non Corrosive Liquid' },
        ],
    },
    {
        name: 'PAP Ferrule Valve',
        image: PAPFerruleValveImage,
        description: 'Compression Type End Connection Ferrule Valve for (PE-Al-PE) Composite Pressure Pipes.',
        sizes: [
            { size: '16\u00D70.50"', suitableFor: '1216 Composite Pipe', boxQty: 10, cartonQty: 100 },
            { size: '20\u00D70.50"', suitableFor: '1620 Composite Pipe', boxQty:  6, cartonQty:  60 },
        ],
        operatingConditions: [
            { parameter: 'Pressure', value: '1.6 MPa' },
            { parameter: 'Temperature', value: '-20\u00B0C to 110\u00B0C' },
            { parameter: 'Medium', value: 'Water, Non Corrosive Liquid' },
        ],

    }
]
