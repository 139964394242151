import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AkarLogo from "../images/brand/AKAR.webp";
import KitecLogo from "../images/brand/KiTEC.webp";
import { closePage, navPath } from "../utils/NavigationUtils";


export default function Products() {

    const navigate = useNavigate();
    
    const closeHandler = () => {
        closePage(navigate);
    }

    const [active, setActive] = useState(false);
    setTimeout(() => setActive(true), 0)

    return (
        <article id="work" className={active ? "active" : ""} >
            <div className="close" onClick={closeHandler}>Close</div>
            <h2 className="major">Products</h2>
            <div className="brands">
                <div className="brand half">
                    <h2 className="minor">AKAR</h2>
                    <span className="image brand-image">
                        <img src={AkarLogo} alt="" />
                    </span>
                    <p>Specialised compression type end connection Ball Valves, Non Return Valves and Overhead Tank Nipples etc, designed and developed for Composite Piping Systems.</p>
                    <Link to={navPath("/products/akar", location.pathname)} className="button" style={{ width: "100%" }}>
                        Read More...
                    </Link>
                </div>
                <div className="brand half">
                    <h2 className="minor">KiTEC</h2>
                    <span className="image brand-image">
                        <img src={KitecLogo} alt="" />
                    </span>
                    <p>An Aluminium and Polyethylene composite pipe that combines the best features of both the materials to form a light, strong and corrosion resistant piping system.</p>
                    <a href="http://kitecindia.in" target="_blank" rel="noopener" className="button" style={{ width: "100%" }}>
                        Read More...
                    </a>
                </div>
            </div>
            <div className="disclaimer">
                AKAR&reg; is a registered trademark of Composite Nirman Materials Pvt. Ltd.
            </div>
        </article>
    )
}
