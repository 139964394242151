import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import { closePage } from "../utils/NavigationUtils";

library.add(faFacebookF, faLinkedin);


export default function ContactForm() {

    const navigate = useNavigate();
    
    const closeHandler = () => {
        closePage(navigate);
    }

    const [submissionResponse, setSubmissionResponse] = useState<{success: boolean, message?: string, error?: string } | undefined>();
    const [loading, setLoading] = useState(false);

    const [formNameValue, setFormNameValue] = useState<string>("");
    const [formEmailValue, setFormEmailValue] = useState<string>("");
    const [formMobileValue, setFormMobileValue] = useState<string>("");
    const [formSubjectValue, setFormSubjectValue] = useState<string>("");
    const [formMessageValue, setFormMessageValue] = useState<string>("");

    const changeHandler = (key: string, value: string) => {
        switch (key) {
            case "name":
                setFormNameValue(value);
                break;
            case "email":
                setFormEmailValue(value);
                break;
            case "mobile":
                setFormMobileValue(value);
                break;
            case "subject":
                setFormSubjectValue(value);
                break;
            case "message":
                setFormMessageValue(value);
                break;        
            default:
        }
    }

    const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => changeHandler(event.currentTarget.name, event.currentTarget.value);
    const textAreaChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => changeHandler(event.currentTarget.name, event.currentTarget.value);

    const querySubmitHandler: React.FormEventHandler<HTMLFormElement> = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        const formValue = {
            token: "",
            name: formNameValue,
            email: formEmailValue,
            mobile: formMobileValue,
            subject: formSubjectValue,
            message: formMessageValue,
        }

        console.log('querySubmitHandler');
        const api_key = '6Lc8SegUAAAAANapyPzXD8Z-JeN2gSw0TyLhzkdi'
        window['grecaptcha'].ready(function() {
            window['grecaptcha'].execute(api_key, {action: 'contact_us'}).then(function(token) {
                formValue.token = token;
                console.log(formValue);
                setSubmissionResponse(undefined);
                setLoading(true);
                fetch('https://m9e6ghpvn8.execute-api.eu-west-1.amazonaws.com/develop/contact-us-form', {
                    method: "POST",
                    body: JSON.stringify(formValue),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    console.log(res.status)
                    if (res.status === 200) {
                        setSubmissionResponse({
                            success: true,
                            message: "Your query has been submitted. We will revert to you for further correspondance!"
                        })
                    }
                    return res.json()
                })
                .then((data) => {
                    console.log(data)
                    const { message, errorMessage } = data;
                    if (message) {
                        setSubmissionResponse({
                            success: true,
                            message
                        })
                    } else if (errorMessage) {
                        setSubmissionResponse({
                            success: false,
                            error: errorMessage
                        });
                    }
                    setLoading(false);
                })
                .catch(ex => {
                    console.error(ex)
                    setLoading(false);
                })
            });
        });
    }

    const queryResetHandler: React.MouseEventHandler = (event: React.MouseEvent) => {
        setFormNameValue("");
        setFormEmailValue("");
        setFormMobileValue("");
        setFormSubjectValue("");
        setFormMessageValue("");
    }

    const [active, setActive] = useState(false);
    setTimeout(() => setActive(true), 0)

    if (submissionResponse && submissionResponse.success) {
        return <ContactSuccess message={submissionResponse.message} />
    } else if (submissionResponse && !submissionResponse.success) {
        return <ContactFailure message={ submissionResponse.error }/>
    }


    return (
        <article id="contact" className={active ? "active" : ""} >
            <div className="close" onClick={closeHandler}>Close</div>
            <h2 className="major">Contact</h2>
            {
                loading ? <div className="loading-container">
                    <FontAwesomeIcon className="icon loading" icon={faCircleNotch}></FontAwesomeIcon>
                </div> : ''
            }
            <form name="contact-form" data-action="contact_us" method="POST" onSubmit={querySubmitHandler} >
                <input type="hidden" name="token" id="token" style={{ display: "none" }} />
                <div className="fields">
                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" minLength={5} value={formNameValue} onChange={inputChangeHandler} required/>
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" value={formEmailValue} onChange={inputChangeHandler} minLength={5} required={ formMobileValue.length <= 0 }/>
                    </div>
                    <div className="field half">
                        <label htmlFor="mobile">Phone</label>
                        <input type="tel" name="mobile" id="mobile" value={formMobileValue} onChange={inputChangeHandler} minLength={10} maxLength={10} required={ formEmailValue.length <= 0 }/>
                    </div>
                    <div className="field">
                        <label htmlFor="subject">Subject</label>
                        <input type="text" name="subject" id="subject" value={formSubjectValue} onChange={inputChangeHandler} required/>
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows={4} value={formMessageValue} onChange={textAreaChangeHandler} required></textarea>
                    </div>
                </div>
                <ul className="actions">
                    <li><button type="submit" data-action="contact_us_submit" className="primary">Send Message</button></li>
                    <li><input type="reset" value="Reset" onClick={queryResetHandler}/></li>
                </ul>
            </form>
            <ul className="icons">
                <li>
                    <a href="mailto:mail@cnm.co.in" target="_blank" rel="noopener" className="icon">
                        <FontAwesomeIcon className="icon" icon={faEnvelope}></FontAwesomeIcon>
                        <span className="label">Email</span>
                    </a>
                </li>
                <li>
                    <a href="tel:+91 9560654567" target="_blank" rel="noopener" className="icon brands">
                        <FontAwesomeIcon className="icon" icon={faPhone}></FontAwesomeIcon>
                        <span className="label">Phone</span>
                    </a>
                </li>
                <li>
                    <a href="https://facebook.com/cnmpl" target="_blank" rel="noopener" className="icon brands">
                        <FontAwesomeIcon className="icon" icon={faFacebookF}></FontAwesomeIcon>
                        <span className="label">Facebook</span>
                    </a>
                </li>
                <li>
                    <a href="https://linkedin.com/company/cnm-india" target="_blank" rel="noopener" className="icon brands">
                        <FontAwesomeIcon className="icon" icon={faLinkedin}></FontAwesomeIcon>
                        <span className="label">LinkedIn</span>
                    </a>
                </li>
            </ul>
        </article>
    )
}

function ContactFailure(props: {message: string | undefined}) {

    const [redirect, setRedirect] = useState(false);    
    const [active, setActive] = useState(false);

    setTimeout(() => setActive(true), 0)
    
    if (redirect) return <Navigate to="/contact" />

    setTimeout(() => { setRedirect(true); }, 5 * 1000);

    return <article id="contact-failed" className={active ? "active" : ""} >
        <h2 className="major">Failed to send the message.</h2>
        <h4>{ props.message ?? "" }</h4>
    </article>
}

function ContactSuccess(props: {message: string | undefined}) {

    const [redirect, setRedirect] = useState(false);
    const [active, setActive] = useState(false);
    
    setTimeout(() => setActive(true), 0)
    
    if (redirect) return <Navigate to="/" />
    
    setTimeout(() => { setRedirect(true); }, 5 * 1000);

    return <article id="contact-thankyou" className={active ? "active" : ""} >
        <h2 className="major">We have received your query.</h2>
        <h4>Thank you. We'll revert to you as soon as possible.</h4>
        <h4>{ props.message ?? ""}</h4>
    </article>
}
