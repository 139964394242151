import { Link } from "react-router-dom";
import { navPath } from "../utils/NavigationUtils";

export default function Error404Page() {
    return (
        <article id="error404" >
            <h2 className="major">Page not found.</h2>
            <p>The Page you are trying to reach does not exist.</p>
            <Link to={navPath("/", "/")} className="button icon solid fa-download">Go Home</Link>
        </article>
    )
}
