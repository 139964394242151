import { useState } from "react";
import { useNavigate } from "react-router";
import { closePage } from "../utils/NavigationUtils";

export default function About() {

    const navigate = useNavigate();

    const closeHandler = () => {
        closePage(navigate);
    }

    const [active, setActive] = useState(false);

    setTimeout(() => setActive(true), 0)

    return  <article id="about" className={active ? "active": ""} >
        <div className="close" onClick={closeHandler}>Close</div>
        <h2 className="major">About</h2>
        <p>We would like to take this opportunity to introduce Composite Nirman Materials Pvt. Ltd. as an aspiring building material marketing company. It was incorporated with the Registrar of Companies in the October of 2007.</p>
        <p>We offer Ball Valves, Non Return Valves, Overhead Tank Nipples, Conceal Valve, Ferrule Valve for Composite Piping Systems and Air Release Valve, Gas Ball Valves for CNG, PNG, LPG, Compressed Air and Vaccum Piping Systems.</p>
        <p>The company is promoted with the aim to provide latest energy efficient, maintenance free, long lasting and environment friendly products, services and technologies for fast growing building construction industries. The promoters have a rich experience of marketing latest technology, innovative products and concepts, which can enhance the construction quality and provide maintenance free service life to the building.</p>
    </article>

}
